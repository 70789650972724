<template>
	<section class="permission">
		<!--基本信息-->
		<div class="psmMk">
			<el-form :model="form" label-width="120px" ref="form">
				<el-form-item label="用户名">
					{{form.id}}
				</el-form-item>
				<el-form-item label="店铺名称">
					{{form.name}}
				</el-form-item>
				<el-form-item label="模块选择">
					<el-checkbox-group v-model="form.module_id">
						<el-checkbox v-for="i in psmNode" :label="i.id" :key="i.id" :disabled="i.id == 1?true:false">{{i.name}}</el-checkbox>
					</el-checkbox-group>
					<!-- <el-tree
						:data="psmNode"
						show-checkbox
						node-key="id"
						:default-expand-all="true"
						:props="defaultProps"
						:indent="24"
						ref="psmNodeTree">
					</el-tree> -->
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click.native="saveSubmit" :loading="Loading" icon="el-icon-circle-check">提交</el-button>
				<el-button @click="goBack" icon="el-icon-circle-close">取消</el-button>
			</div>
		</div>
	</section>
</template>

<script>
	import {getStoreInfo, modulesUpdate, advanceModulesShow } from '../../api/api';
	export default {
		data() {
			return {
				//编辑ID
				aID: this.$route.query.id,
				store_id: this.$route.query.store_id,
				// defaultProps: {
				// 	children: 'children',
				// 	label: 'name'
				// },
				//权限节点选项
				psmNode: [],
				//创建新增
				Loading: false,
				//表单数据
				form: {
					name: '',
					id: '',
					module_id: [],
				}
			}
		},
		methods: {
			//获取权限节点
			getModule() {
				advanceModulesShow().then((res) => {
					if(res.code == 1){
						this.psmNode = res.data;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取信息
			getInfo() {
				getStoreInfo({store_id:this.aID}).then((res) => {
					if(res.code == 1){
						this.form.name = res.data.name;
						this.form.id = res.data.id;
						this.form.module_id = res.data.module_id?res.data.module_id.split(',').map(Number):[];
						let flag = !0;
						this.form.module_id.some((item)=>{
							if(item === 1){
								flag = !1;
								return true
							}
						})
						flag && this.form.module_id.push(1)
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//提交角色名称基本信息
			saveSubmit: function () {
				//this.getCheckedKeys()
				console.log(this.form.module_id)
				// this.Loading = true;
				const para = {
					module_id: this.form.module_id.toString(),
					store_id: this.store_id
				}
				modulesUpdate(para).then((res) => {
					this.Loading = false;
					if(res.code == 1){
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.goBack()
					}else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}

				});
			},
			//获取已选择节点
			// getCheckedKeys(){
			// 	this.form.menu_id = this.$refs.psmNodeTree.getCheckedKeys()
			// },
			// setCheckedKeys(val) {
			// 	val && this.$refs.psmNodeTree.setCheckedKeys(val);
			// },
		},
		mounted() {
			this.getModule();
			this.getInfo();
		}
	}

</script>